<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              class="none-bottom" 
              type="search" 
              name="plantCd" 
              v-model="searchParam.plantCd"
              @datachange="plantChange"  />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 기간 -->
            <c-datepicker
              :range="true"
              label="LBLPERIOD"
              type="year"
              name="period"
              defaultStart="-2y"
              defaultEnd="0y"
              v-model="period"
              @datachange="plantChange"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3" style="padding-top:5px !important;">
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="plantChange" />
          </div> -->
        </template>
      </c-search-box>
      <div class="row">
        <div class="col-6">
          <apexchart 
            class="accidentChart"
            ref="yearOcuurChart" 
            height="350" 
            type="bar"
            :options="yearOcuurChart.chartOptions" 
            :series="yearOcuurChart.series"></apexchart>
        </div>
        <div class="col-4">
          <apexchart 
            class="accidentChart"
            ref="kindOcuurChart" 
            height="380" 
            :options="kindOcuurChart.chartOptions" 
            :series="kindOcuurChart.series"></apexchart>
        </div>
        <div class="col-12">
          <apexchart 
            class="accidentChart"
            ref="hourOcuurChart" 
            height="350" 
            type="line"
            :options="hourOcuurChart.chartOptions" 
            :series="hourOcuurChart.series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'accident-status',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      fullscreen: false,
      colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
        iimUpOccurMasterId: '',
      },
      yearOcuurChart: {
        chartOptions: {
          chart: {
            type: 'bar',
          },
          title: {
            text: '년도별 아차사고 발생 건 수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded',
              distributed: true
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#fcfafa"]
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: {
            style: {
              colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
              fontSize: '12px'
            }
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [],
        chartWidth: '80%',
      },
      kindOcuurChart: {
        chartOptions: {
          title: {
            text: '재해유형별 아차사고 발생 건 수'
          },
          chart: {
            type: 'polarArea',
          },
          colors: ['#008FFB','#00E396','#FEB019','#FF4560','#775DD0','#3F51B5','#03A9F4','#4CAF50','#F9CE1D','#FF9800','#A300D6','#5653FE','#1B998B','#F86624','#13D8AA'],
          labels: [],
          legend: {
            position: 'bottom',
          },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          fill: {
            type: 'gradient',
          },
          yaxis: [
            {
              labels: {
                formatter: function(val) {
                  return Number.isInteger(Number(val)) ? Number(val).toFixed(0) : Number(val).toFixed(1);
                }
              }
            }
          ],
        },
        series: [
          {
            name: '',
            data: [],
          },
        ],
        chartWidth: '80%',
      },
      
      hourOcuurChart: {
        chartOptions: {
          title: {
            text: '시간대별 아차사고 발생 건 수'
          },
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            offsetY: 0,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: ['00시', '01시', '02시', '03시', '04시', '05시', '06시', '07시', '08시','09시','10시','11시','12시',
            '13시','14시','15시','16시','17시','18시','19시','20시','21시','22시','23시'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
        ],
        chartWidth: '80%',
      },
      period: [],
      options: [],
      selectProcessCd: '',
      editable: true,
      searchUrl: '',
      count: 0,
      yearUrl: '',
      kindUrl: '',
      hourUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.yearUrl = selectConfig.sop.iim.accident.near.status.year.url;
      this.hourUrl = selectConfig.sop.iim.accident.near.status.hour.url;
      this.kindUrl = selectConfig.sop.iim.accident.near.status.kind.url;
      // 년도 설정
      // let years = [];
      // for (let i = 0; i < 5; i++) {
      //   years.splice(0, 0, this.$comm.getPrevYear(i));
      // }
      // this.yearOcuurChart.chartOptions.xaxis.categories = years;

      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];

      // url setting
      this.getYearList();
      this.getKindList();
      this.getHourList();
      this.setChartLabel();
    },
    setChartLabel() {

      this.$refs['yearOcuurChart'].refresh();
      this.$refs['kindOcuurChart'].refresh();
      this.$refs['hourOcuurChart'].refresh();
    },
    getYearList() {
      this.$http.url = this.yearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.yearOcuurChart.series = [];
        this.yearOcuurChart.chartOptions.xaxis.categories = [];
        // this.$_.forEach(_result.data, _item => {
        //   this.yearOcuurChart.chartOptions.xaxis.categories.push(_item.occurYear)
        // })
        this.$_.forEach(_result.data, _item => {
          // 년도에 따른 idx를 구하여 series.data의 위치 통일
          if (this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear) === -1) {
            this.yearOcuurChart.chartOptions.xaxis.categories.push(_item.occurYear);
          }

          let idx = this.yearOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurYear)
          if (this.$_.findIndex(this.yearOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            let _data = [];
            this.$_.forEach(this.yearOcuurChart.chartOptions.xaxis.categories, _item => {
              _data.push(0)
            })

            this.yearOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              data: _data
            })
          }
          this.yearOcuurChart.series[this.$_.findIndex(this.yearOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] = _item.yearCount
        })
        this.$refs['yearOcuurChart'].refresh();
        this.yearOcuurChart.chartWidth = '90%';
      },);
    },
    getKindList() {
      this.$http.url = this.kindUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.kindOcuurChart.chartOptions, 'labels', this.$_.map(_result.data, 'iimNearKindName'))
        this.$set(this.kindOcuurChart, 'series', this.$_.map(_result.data, 'kindCount')) 
        this.$nextTick(function () {
          this.$refs['kindOcuurChart'].refresh();
          this.kindOcuurChart.chartWidth = '90%';
        })
      },);
    },
    getHourList() {
      this.$http.url = this.hourUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.hourOcuurChart.series = [];

        this.$_.forEach(_result.data, _item => {
        // 년도에 따른 idx를 구하여 series.data의 위치 통일
          let idx = this.hourOcuurChart.chartOptions.xaxis.categories.indexOf(_item.occurTime)
          if (this.$_.findIndex(this.hourOcuurChart.series, { plantCd: _item.plantCd}) === -1) {
            this.hourOcuurChart.series.push({
              plantCd: _item.plantCd,
              name: _item.plantName,
              //24개
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            })
          }

          this.hourOcuurChart.series[this.$_.findIndex(this.hourOcuurChart.series, 
          { plantCd: _item.plantCd })].data[idx] = _item.timeCount
        })
        this.hourOcuurChart.chartWidth = '90%';
        this.$refs['hourOcuurChart'].refresh();
      },);
    },
    plantChange() {
      if(this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.getYearList();
      this.getKindList();
      this.getHourList();
    },
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important

// .el-cascader-node
//   padding: 0 30px 0 5px !important
// .el-cascader-menu
//   min-width: auto
// .el-cascader-node__label
//   padding: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  // border-right: #dee2e6 solid 0 !important
  // border-bottom: #dee2e6 solid 1px !important
  padding: 0px !important
.widget-yearOcuurChart
  padding: 5px !important
.widget-yearOcuurChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important

.accidentChart
  padding: 20px !important
  border: 1px solid #dee2e6
  border-top: 3px solid #dee2e6
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>